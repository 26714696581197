import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/scss/bootstrap.scss';
import './assets/css/icofont.min.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/brackets.css';

import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Swiper from 'swiper';
import 'swiper/css';
import ScrollToTop from "./component/layout/scrolltop";
import loadable from '@loadable/component';
import Navigate from "../../util/Navigate";
import Alerts from '../../util/Alerts';

// Lazy-loaded components
const ErrorPage = loadable(() => import("./pages/misc/errorpage"));
const HomeTwo = loadable(() => import('./pages/misc/hometwo'));
const PrivacyPage = loadable(() => import("./pages/misc/privacy"));
const TermsPage = loadable(() => import("./pages/misc/terms"));
const ContactPage = loadable(() => import("./pages/misc/contact"));
const DataRemovalPage = loadable(() => import("./pages/misc/dataremoval"));
const GDPRPage = loadable(() => import("./pages/misc/gdpr"));
const AccessDeniedPage = loadable(() => import("./pages/misc/accessdenied"));
const BenefitsPage = loadable(() => import('./pages/misc/benefits'));
const CreatorsPage = loadable(() => import('./pages/misc/creators'));
const MarketingPage = loadable(() => import('./pages/misc/marketing'));
const RevenuePage = loadable(() => import('./pages/misc/revenue'));
const FeaturesPage = loadable(() => import('./pages/misc/features'));
const InstallationPage = loadable(() => import('./pages/misc/installation'));
const AboutPage = loadable(() => import('./pages/misc/about'));
const CrawlPage = loadable(() => import('./pages/misc/crawl'));
const CreatorsRewardsPage = loadable(() => import('./pages/misc/creatorsrewards'));
const CreatorsPublishersPage = loadable(() => import('./pages/misc/creatorspublishers'));
const CreatorsInfluencerMacPage = loadable(() => import('./pages/misc/creatorsinfluencersmac'));
const CreatorsInfluencerLinuxPage = loadable(() => import('./pages/misc/creatorsinfluencerslinux'));
const CreatorsPublishersMacPage = loadable(() => import('./pages/misc/creatorspublishersmac'));
const CreatorsPublishersLinuxPage = loadable(() => import('./pages/misc/creatorspublisherslinux'));
const CreatorsCalculator = loadable(() => import('./pages/misc/calculator'));

const AuthComplete = loadable(() => import("./pages/auth/auth_complete"));
const AuthFacebook = loadable(() => import("./pages/auth/auth_facebook"));
const AuthTwitch = loadable(() => import("./pages/auth/auth_twitch"));
const AuthTikTok = loadable(() => import("./pages/auth/auth_tiktok"));
const AuthYoutube = loadable(() => import("./pages/auth/auth_youtube"));
const AuthStripe = loadable(() => import("./pages/auth/auth_stripe"));
const AuthGoogle = loadable(() => import("./pages/auth/auth_google"));
const AuthMicrosoft = loadable(() => import("./pages/auth/auth_microsoft"));
const AuthMicrosoftTeams = loadable(() => import("./pages/auth/auth_teams"));
const AuthReddit = loadable(() => import('./pages/auth/auth_reddit'));
const AuthTwitter = loadable(() => import('./pages/auth/auth_twitter'));
const AuthDocusign = loadable(() => import('./pages/auth/auth_docusign'));
const AuthSimplesign = loadable(() => import('./pages/auth/auth_simplesign'));

const SignUp = loadable(() => import("./pages/auth/signup"));
const ForgotPassword = loadable(() => import("./pages/auth/forgotpassword"));
const ResetPassword = loadable(() => import("./pages/auth/resetpassword"));
const LogIn = loadable(() => import("./pages/auth/login"));
const RegisterStep2 = loadable(() => import('./pages/auth/register_step2'));

const AccountUpdatePage = loadable(() => import("./pages/account/profile"));
const AccountVerifyPage = loadable(() => import("./pages/account/verify"));

const WatchRecording = loadable(() => import('./pages/streams/watchrecording'));
const Cohostwatchstream = loadable(() => import('./pages/streams/cohostwatchstream'));

// Tips
const EmojisPage = loadable(() => import('./pages/tips/emojis'));
const EmojisWatchPage = loadable(() => import('./pages/tips/watch'));

// Communities
const CommunitiesList = loadable(() => import('./pages/communities/CommunitiesList'));
const CommunitiesCreate = loadable(() => import('./pages/communities/CommunitiesCreate'));
const CommunitiesView = loadable(() => import('./pages/communities/CommunitiesView'));
const CommunitiesManage = loadable(() => import('./pages/communities/CommunitiesManage'));
const CommunitiesUpdate = loadable(() => import('./pages/communities/CommunitiesUpdate'));
const CommunitiesFeatures = loadable(() => import('./pages/communities/CommunitiesFeatures'));
const CommunitiesMedia = loadable(() => import('./pages/communities/CommunitiesMedia'));
const CommunitiesSocial = loadable(() => import('./pages/communities/CommunitiesSocial'));
const CommunitiesCname = loadable(() => import('./pages/communities/CommunitiesCname'));
const CommunitiesAccessibility = loadable(() => import('./pages/communities/CommunitiesAccessibility'));
const CommunitiesUsers = loadable(() => import('./pages/communities/CommunitiesUsers'));
const CommunitiesUsersInvite = loadable(() => import('./pages/communities/CommunitiesUsersInvite'));
const CommunitiesUsersManage = loadable(() => import('./pages/communities/CommunitiesUsersManage'));
const CommunitiesCss = loadable(() => import('./pages/communities/CommunitiesCss'));
const CommunitiesAdminList = loadable(() => import('./pages/communities/CommunitiesAdminList'));
const CommunitiesCreditCardsPage = loadable(() => import('./pages/communities/CommunitiesCreditCards'));
const CommunitiesNewsletter = loadable(() => import('./pages/communities/CommunitiesNewsletter'));
const CommunitiesEmailTemplatesPage = loadable(() => import('./pages/communities/CommunitiesEmailTemplates'));
const CommunityContractsPage = loadable(() => import('./pages/communities/CommunitiesContracts'));
const CommunitiesGettingStarted = loadable(() => import('./pages/communities/CommunitiesGettingStarted'));

const PublisherSubscribePage = loadable(() => import('./pages/publishers/publishers_subscribe'));

// Creators
const CreatorsOverviewPage = loadable(() => import('./pages/creators/CreatorsOverview'));
const CreatorsMicroPage = loadable(() => import('./pages/creators/CreatorsMirco'));
const CreatorsBlackPage = loadable(() => import('./pages/creators/CreatorsBlack'));
const CreatorsEditingToolPage = loadable(() => import('./pages/creators/CreatorsEditing'));
const CreatorsFeaturesPage = loadable(() => import('./pages/creators/CreatorsFeatures'));
const CreatorsMulticatingPage = loadable(() => import('./pages/creators/CreatorsMulticasting'));
const CreatorsTiktokStreamPage = loadable(() => import('./pages/creators/CreatorsTiktokStream'));
const CreatorsOptimizationPage = loadable(() => import('./pages/creators/CreatorsOptimization'));
const CreatorsGettingStarted = loadable(() => import('./pages/creators/CreatorsGettingStarted'));
const CreatorsPlayTestersPage = loadable(() => import('./pages/creators/CreatorPlayTesters'));

// Creators Onboarding
const CreatorOnboardinStep1Page = loadable(() => import('./pages/creators/creator_onboarding_step_1'));
const CreatorOnboardinStep2Page = loadable(() => import('./pages/creators/creator_onboarding_step_2'));
const CreatorOnboardinStep3Page = loadable(() => import('./pages/creators/creator_onboarding_step_3'));
const CreatorOnboardinStep4Page = loadable(() => import('./pages/creators/creator_onboarding_step_4'));
const CreatorOnboardinStep5Page = loadable(() => import('./pages/creators/creator_onboarding_step_5'));

// Campaigns
const CampaignsListPage = loadable(() => import('./pages/campaigns/CampaignsListPage'));
const CampaignCreatePage = loadable(() => import('./pages/campaigns/CampaignCreatePage'));
const CampaignUpdatePage = loadable(() => import('./pages/campaigns/CampaignUpdatePage'));
const CampaignsViewPage = loadable(() => import('./pages/campaigns/CampaignsViewPage'));
const CampaignsFindInfluencersPage = loadable(() => import('./pages/campaigns/CampaignsFindInfluencersPage'));
const CampaignsInviteInfluencerPage = loadable(() => import('./pages/campaigns/CampaignsInviteInfluencerPage'));
const CampaignsRecommendedInfluencersPage = loadable(() => import('./pages/campaigns/CampaignsRecommendedInfluencersPage'));
const CampaignsViewCreatorPage = loadable(() => import('./pages/campaigns/CampaignsViewCreatorPage'));
const CampaignsViewCreatorPerformancePage = loadable(() => import('./pages/campaigns/CampaignsViewCreatorPerformancePage'));
const CampaignsViewCreatorContractPage = loadable(() => import('./pages/campaigns/CampaignsViewCreatorContractPage'));
const CampaignsViewCreatorPaymentPage = loadable(() => import('./pages/campaigns/CampaignsViewCreatorPaymentPage'));
const CampaignsMessageInfluencerPage = loadable(() => import('./pages/campaigns/CampaignsMessageInfluencerPage'));
const CampaignsStartPage = loadable(() => import('./pages/campaigns/CampaignsStartPage'));
const CampaignsViewInfluencerPage = loadable(() => import('./pages/campaigns/CampaignsViewInfluencerPage'));
const CampaignsInvitePage = loadable(() => import('./pages/campaigns/CampaignsInvitePage'));
const CampaignsInfluencersPage = loadable(() => import('./pages/campaigns/CampaignsInfluencersPage'));
const CampaignUpdateInfluencerPage = loadable(() => import('./pages/campaigns/CampaignUpdateInfluencerPage'));
const CampaignsLedgerPage = loadable(() => import('./pages/campaigns/CampaignsLedgerPage'));
const CampaignsContentPage = loadable(() => import('./pages/campaigns/CampaignsContentPage'));
const CampaignsStatisticsPage = loadable(() => import('./pages/campaigns/CampaignsStatisticsPage'));
const CampaignsUpdateInfluencerInviteCompensationPage = loadable(() => import('./pages/campaigns/CampaignsUpdateInfluencerInviteCompensationPage'));
const CampaignsUpdateInfluencerInviteContractPage = loadable(() => import('./pages/campaigns/CampaignsUpdateInfluencerInviteContractPage'));
const CampaignUpdateMediaPage = loadable(() => import('./pages/campaigns/CampaignUpdateMediaPage'));
const CampaignUpdateLinksPage = loadable(() => import('./pages/campaigns/CampaignUpdateLinksPage'));
const CampaignsViewInfluencerInvitePage = loadable(() => import('./pages/campaigns/CampaignsViewInfluencerInvitePage'));

// Influencers
const InfluencerCampaignsPage = loadable(() => import('./pages/influencers/InfluencerCampaignsPage'));
const InfluencerFindCampaignsPage = loadable(() => import('./pages/influencers/InfluencerFindCampaignsPage'));
const InfluencerViewCampaignPage = loadable(() => import('./pages/influencers/InfluencerViewCampaignPage'));
const InfluencerManageCampaignPage = loadable(() => import('./pages/influencers/InfluencerManageCampaignPage'));
const InfluencerMyCampaignsPage = loadable(() => import('./pages/influencers/InfluencerMyCampaignsPage'));
const InfluencerViewCampaignInvitePage = loadable(() => import('./pages/influencers/InfluencerViewCampaignInvitePage'));
const InfluencerPayoutsPage = loadable(() => import('./pages/influencers/InfluencerPayoutsPage'));
const InfluencerBeginCampaignPage = loadable(() => import('./pages/influencers/InfluencerBeginCampaignPage'));
const InfluencerCampaignApplyPage = loadable(() => import('./pages/influencers/InfluencerCampaignApplyPage'));
const InfluencerInvitesPage = loadable(() => import('./pages/influencers/InfluencerInvitesPage'));
const InfluencerCreatePostPage = loadable(() => import('./pages/influencers/InfluencerCreatePostPage'));
const InfluencerListSocialMediaPostsPage = loadable(() => import('./pages/influencers/InfluencerListSocialMediaPostsPage'));
const InfluencerUpdatePostPage = loadable(() => import('./pages/influencers/InfluencerUpdatePostPage'));

const InfluencerMessagesCreatePage = loadable(() => import('./pages/influencers/InfluencerMessagesCreatePage'));
const InfluencerMessagesListPage = loadable(() => import('./pages/influencers/InfluencerMessagesListPage'));
const InfluencerMessagesReadPage = loadable(() => import('./pages/influencers/InfluencerMessagesReadPage'));

const InfluencerDownloadAppPage = loadable(() => import('./pages/influencers/InfluencerDownloadAppPage'));
const InfluencerGettingStartedPage = loadable(() => import('./pages/influencers/InfluencerGettingStartedPage'));

const InfluencerTutorialsPage = loadable(() => import('./pages/influencers/InfluencerTutorialsPage'));
const InfluencerTutorialsContentPage = loadable(() => import('./pages/influencers/InfluencerTutorialsContentPage'));
const InfluencerTutorialsInvitesPage = loadable(() => import('./pages/influencers/InfluencerTutorialsInvitesPage'));
const InfluencerTutorialsGamesPage = loadable(() => import('./pages/influencers/InfluencerTutorialsGamesPage'));

// Messages
const MessagesListPage = loadable(() => import('./pages/messages/MessagesListPage'));
const MessagesCreatePage = loadable(() => import('./pages/messages/MessagesCreatePage'));
const MessagesReadPage = loadable(() => import('./pages/messages/MessagesReadPage'));

// Publishers
const PublishersDashboardPage = loadable(() => import('./pages/publishers/publisher_dashboard'));
const PublishersGamificationPage = loadable(() => import('./pages/publishers/publishers_gamification'));
const PublishersOptimizationPage = loadable(() => import('./pages/publishers/publishers_optomization'));
const PublisherBenefitPage = loadable(() => import('./pages/publishers/publishers_benefits'));
const PublisherUserAcquistion = loadable(() => import('./pages/publishers/publishers_user_acquistion'));
const PublisherRegisterPage = loadable(() => import('./pages/publishers/publishers_register'));
const PublisherPilotsPage = loadable(() => import('./pages/publishers/publishers_pilots'));
const PublisherPricingPage = loadable(() => import('./pages/publishers/publishers_pricing'));
const PublishersDatabasePage = loadable(() => import('./pages/publishers/publishers_database'));
const PublishersAIPage = loadable(() => import('./pages/publishers/publishers_ai'));
const PublishersCRMPage = loadable(() => import('./pages/publishers/publishers_crm'));
const PublishersContentPage = loadable(() => import('./pages/publishers/publishers_content'));
const PublishersHolidayPage = loadable(() => import('./pages/publishers/publisher_holiday_page'));
const PublishersPlayersPage = loadable(() => import('./pages/publishers/publishers_players_page'));
const PublishersNewsletterPage = loadable(() => import('./pages/publishers/publishers_newsletter_page'));
const PublishersShowcasesPage = loadable(() => import('./pages/publishers/publisher_showcases_page'));
const PublishersMediaKitPage = loadable(() => import('./pages/publishers/publishers_mediakit_page'));
const PublisherDevlogPage = loadable(() => import('./pages/publishers/publisher_devlog_page'));
const PublisherSocialSchedulerPage = loadable(() => import('./pages/publishers/publisher_social_page'));
const PublishersCommunityPage = loadable(() => import('./pages/publishers/publishers_community_page'));

const PublisherGameFreeToolsPage = loadable(() => import('./pages/publishers/publishers_tools'));
const PublisherGameForumsPage = loadable(() => import('./pages/publishers/publishers_tools_forums'));
const PublisherGameWikiPage = loadable(() => import('./pages/publishers/publishers_tools_wiki'));
const PublisherGamDatabasePage = loadable(() => import('./pages/publishers/publishers_tools_databases'));
const PublisherGamePricingPage = loadable(() => import('./pages/publishers/publishers_tools_pricing'));
const PublisherGamePRPage = loadable(() => import('./pages/publishers/publishers_tools_pr'));
const PublisherGameInfluencersPage = loadable(() => import('./pages/publishers/publishers_tools_influencers'));
const PublisherGameShowsPage = loadable(() => import('./pages/publishers/publishers_tools_gameshows'));
const PublisherGameGuidePage = loadable(() => import('./pages/publishers/publishers_tools_guide'));
const PublisherGameNewsletterPage = loadable(() => import('./pages/publishers/publishers_tools_mailers'));

// Publishers Onboarding
const PublisherOnboardinStep1Page = loadable(() => import('./pages/publishers/publisher_onboarding_step_1'));
const PublisherOnboardinStep2Page = loadable(() => import('./pages/publishers/publisher_onboarding_step_2'));
const PublisherOnboardingStep3Page = loadable(() => import('./pages/publishers/publisher_onboarding_step_3'));
const PublisherOnboardingStep4Page = loadable(() => import('./pages/publishers/publisher_onboarding_step_4'));

// Publishers Messages
const PublisherMessagesCreatePage = loadable(() => import('./pages/publishers/publisher_messages_create_page'));
const PublisherMessagesListPage = loadable(() => import('./pages/publishers/publisher_messages_list_page'));
const PublisherMessagesReadPage = loadable(() => import('./pages/publishers/publisher_messages_read_page'));

// Publishers Tutorials
const PublisherTutorialsPage = loadable(() => import('./pages/publishers/publisher_tutorials'));
const PublisherTutorialInvitePipelinesPage = loadable(() => import('./pages/publishers/publisher_tutorial_invite_pipelines'));
const PublisherTutorialInviteDatabasePage = loadable(() => import('./pages/publishers/publisher_tutorial_invite_database'));
const PublisherTutorialInviteRecommendedPage = loadable(() => import('./pages/publishers/publisher_tutorial_invite_recommended'));
const PublisherTutorialInvitesPage = loadable(() => import('./pages/publishers/publisher_tutorial_invite_invite'));
const PublisherTutorialInviteCustomEmailsPage = loadable(() => import('./pages/publishers/publisher_tutorial_invite_custom_emails'));
const PublisherTutorialInviteNotesPage = loadable(() => import('./pages/publishers/publisher_tutorial_invite_notes'));
const PublisherTutorialInviteNegotiationsPage = loadable(() => import('./pages/publishers/publisher_tutorial_invite_negotiations'));
const PublisherTutorialCampaignsOverviewPage = loadable(() => import('./pages/publishers/publisher_tutorial_campaigns_overview'));
const PublisherTutorialCampaignsCreatePage = loadable(() => import('./pages/publishers/publisher_tutorial_campaigns_create'));
const PublisherTutorialCampaignsChecklistPage = loadable(() => import('./pages/publishers/publisher_tutorial_campaigns_checklist'));
const PublisherTutorialCampaignsRateCardPage = loadable(() => import('./pages/publishers/publisher_tutorial_campaigns_ratecard'));

const PublisherTutorialSchedulerCreatePage = loadable(() => import('./pages/publishers/publisher_tutorial_scheduler_create'));


const ReportsMainPage = loadable(() => import('./pages/reports/ReportsMainPage'));
const ReportsViewTitlePage = loadable(() => import('./pages/reports/ReportsViewTitlePage'));

// Mediakit
const MediakitListPage = loadable(() => import('./pages/mediakits/MediakitListPage'));
const MediakitCreatePage = loadable(() => import('./pages/mediakits/MediakitCreatePage'));
const MediakitUpdatePage = loadable(() => import('./pages/mediakits/MediakitUpdatePage'));
const MediakitViewPage = loadable(() => import('./pages/mediakits/MediakitViewPage'));
const MediakitStandalonePage = loadable(() => import('./pages/mediakits/MediaKitStandAlone'));
const MediakitSharePage = loadable(() => import('./pages/mediakits/MediakitSharePage'));

// Playtesting
const PlayTestGamesListPage = loadable(() => import('./pages/testers/PlayTestGamesListPage'));
const PlayTestGamesCreatePage = loadable(() => import('./pages/testers/PlayTestGamesCreatePage'));
const PlayTestsListPage = loadable(() => import('./pages/testers/PlayTestsListPage'));
const PlayTestDetailsPage = loadable(() => import('./pages/testers/PlayTestDetailsPage'));
const PlayTestManageQuestionsPage = loadable(() => import('./pages/testers/PlayTestManageQuestionsPage'));
const PlayTestInviteTestersPage = loadable(() => import('./pages/testers/PlayTestInviteTestersPage'));
const PlayTestGamesUpdatePage = loadable(() => import('./pages/testers/PlayTestGamesUpdatePage'));
const PlayTestReviewTestPage = loadable(() => import('./pages/testers/PlayTestReviewTestPage'));
const PlayTestGamesPreviewPage = loadable(() => import('./pages/testers/PlayTestGamesPreviewPage'));
const PlayTestMessagePage = loadable(() => import('./pages/testers/PlayTestMessagePage'));

const PlayTestFindGamesToTestPage = loadable(() => import('./pages/players/PlayTestFindGamesToTestPage'));
const PlayTestMyPlayTestsPage = loadable(() => import('./pages/players/PlayTestMyPlayTestsPage'));
const PlayTestDetailsForTesterPage = loadable(() => import('./pages/players/PlayTestDetailsForTesterPage'));
const PlayTestSubmitPlayTestAnswersPage = loadable(() => import('./pages/players/PlayTestSubmitPlayTestAnswersPage'));
const PlayTestGameDetailsPage = loadable(() => import('./pages/players/PlayTestGameDetailsPage'));

// Game Shows
const GameShowsListPage = loadable(() => import('./pages/gameshows/GameShowsListPage'));
const GameShowsCreatePage = loadable(() => import('./pages/gameshows/GameShowsCreatePage'));
const GameShowsViewPage = loadable(() => import('./pages/gameshows/GameShowsViewPage'));
const GameShowsUpdatePage = loadable(() => import('./pages/gameshows/GameShowsUpdatePage'));
const GameShowsPromotePage = loadable(() => import('./pages/gameshows/GameShowsPromotePage'));

// Newsletters
const NewslettersListPage = loadable(() => import('./pages/newsletters/NewslettersListPage'));
const NewslettersCreatePage = loadable(() => import('./pages/newsletters/NewslettersCreatePage'));
const NewsletterViewPage = loadable(() => import('./pages/newsletters/NewsletterViewPage'));
const NewsletterUpdatePage = loadable(() => import('./pages/newsletters/NewsletterUpdatePage'));
const NewsletterSubscribersPage = loadable(() => import('./pages/newsletters/NewsletterSubscribersPage'));
const NewsletterSubscribePage = loadable(() => import('./pages/newsletters/NewsletterSubscribePage'));
const NewsletterImportPage = loadable(() => import('./pages/newsletters/NewsletterImportPage'));
const NewsletterCampaignCreatePage = loadable(() => import('./pages/newsletters/NewsletterCampaignCreatePage'));
const NewsletterCampaignSendPage = loadable(() => import('./pages/newsletters/NewsletterCampaignSendPage'));
const NewsletterCampaignUpdatePage = loadable(() => import('./pages/newsletters/NewsletterCampaignUpdatePage'));
const NewsletterCampaignViewPage = loadable(() => import('./pages/newsletters/NewsletterCampaignViewPage'));

// Scheduler
const SchedulerListPage = loadable(() => import('./pages/scheduler/SchedulerListPage'));
const SchedulerCreatePage = loadable(() => import('./pages/scheduler/SchedulerCreatePage'));
const SchedulerSocialPage = loadable(() => import('./pages/scheduler/SchedulerSocialPage'));
const SchedulerUpdatePage = loadable(() => import('./pages/scheduler/SchedulerUpdatePage'));
const SchedulerViewPage = loadable(() => import('./pages/scheduler/SchedulerViewPage'));
const SchedulerListUpdatesPage = loadable(() => import('./pages/scheduler/SchedulerListUpdatesPage'));
const SchedulerListSocialMediaPostsPage = loadable(() => import('./pages/scheduler/SchedulerListSocialMediaPostsPage'));
const SchedulerUsersPage = loadable(() => import('./pages/scheduler/SchedulerUsersPage'));
const SchedulerInviteUserPage = loadable(() => import('./pages/scheduler/SchedulerInviteUserPage'));
const SchedulerTonePage = loadable(() => import('./pages/scheduler/SchedulerTonePage'));
const SchedulerReportsPage = loadable(() => import('./pages/scheduler/SchedulerReportsPage'));
const SchedulerCreateUpdatePage = loadable(() => import('./pages/scheduler/SchedulerCreateUpdatePage'));
const SchedulerUpdateUpdatePage = loadable(() => import('./pages/scheduler/SchedulerUpdateUpdatePage'));
const SchedulerUpdateSocialMediaPost = loadable(() => import('./pages/scheduler/SchedulerUpdateSocialMediaPost'));
const SchedulerViewSocialMediaPost = loadable(() => import('./pages/scheduler/SchedulerViewSocialMediaPost'));


const LiveChatWidget = loadable(() => import("@livechat/widget-react").then(module => ({ default: module.LiveChatWidget })));

function Template() {
  const [loadLiveChat, setLoadLiveChat] = useState(false);
  const [oauthErrorMessage, setOauthErrorMessage] = useState(null);

  const location = useLocation();

  useEffect(() => {
    // Check for the oauth_error_message query parameter
    const searchParams = new URLSearchParams(location.search);
    const errorMessage = searchParams.get("oauth_error_message");

    if (errorMessage) {
      // Set the error message state to display it
      setOauthErrorMessage(errorMessage);

      Alerts.display("Authentication Failure", errorMessage);

      // Remove the query parameter from the URL
      searchParams.delete("oauth_error_message");
      const newSearch = searchParams.toString();
      const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => { 
      setLoadLiveChat(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  function handleNewEvent(event) {
    console.log('LiveChatWidget.onNewEvent', event);
  }


  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>

        <Route path="/" element={<HomeTwo />} />

        <Route path={Navigate.homePage()} element={<HomeTwo />} />
        <Route path={Navigate.privacyPage()} element={<PrivacyPage />} />
        <Route path={Navigate.termsPage()} element={<TermsPage />} />
        <Route path={Navigate.contactPage()} element={<ContactPage />} />
        <Route path={Navigate.dataRemovalPage()} element={<DataRemovalPage />} />
        <Route path={Navigate.gdprPage()} element={<GDPRPage />} />
        <Route path={Navigate.accessDeniedPage()} element={<AccessDeniedPage />} />
        <Route path={Navigate.marketingPage()} element={<MarketingPage />} />
        <Route path={Navigate.revenuePage()} element={<RevenuePage />} />
        <Route path={Navigate.featuresPage()} element={<FeaturesPage />} />
        <Route path={Navigate.installationPage()} element={<InstallationPage />} />
        <Route path={Navigate.benefitsPage()} element={<BenefitsPage />} />
        <Route path={Navigate.creatorsPage()} element={<CreatorsPage />} />
        <Route path={Navigate.aboutPage()} element={<AboutPage />} />
        <Route path={Navigate.crawlPage()} element={<CrawlPage />} />

        <Route path={Navigate.creatorsInfluencersPage()} element={<CreatorsRewardsPage />} />
        <Route path={Navigate.creatorsOverviewPage()} element={<CreatorsOverviewPage />} />
        <Route path={Navigate.creatorsMicroPage()} element={<CreatorsMicroPage />} />
        <Route path={Navigate.creatorsBlackPage()} element={<CreatorsBlackPage />} />
        <Route path={Navigate.creatorsInfluencersMacPage()} element={<CreatorsInfluencerMacPage />} />
        <Route path={Navigate.creatorsInfluencersLinuxPage()} element={<CreatorsInfluencerLinuxPage />} />
        <Route path={Navigate.creatorsPublishersPage()} element={<CreatorsPublishersPage />} />
        <Route path={Navigate.creatorsPublishersMacPage()} element={<CreatorsPublishersMacPage />} />
        <Route path={Navigate.creatorsPublishersLinuxPage()} element={<CreatorsPublishersLinuxPage />} />
        <Route path={Navigate.creatorsCalculator()} element={<CreatorsCalculator />} />
        <Route path={Navigate.creatorsEditing()} element={<CreatorsEditingToolPage />} />
        <Route path={Navigate.creatorsFeatures()} element={<CreatorsFeaturesPage />} />
        <Route path={Navigate.creatorsMultistreamingPage()} element={<CreatorsMulticatingPage />} />
        <Route path={Navigate.creatorsTiktokstreamPage()} element={<CreatorsTiktokStreamPage />} />
        <Route path={Navigate.creatorsOptimizationPage()} element={<CreatorsOptimizationPage />} />
        <Route path={Navigate.creatorsGettingStartedPage()} element={<CreatorsGettingStarted />} />
        <Route path={Navigate.creatorsPlayTestersPage()} element={<CreatorsPlayTestersPage />} />


        <Route path={Navigate.creatorsOnboardingStep1Page()} element={<CreatorOnboardinStep1Page />} />
        <Route path={Navigate.creatorsOnboardingStep2Page()} element={<CreatorOnboardinStep2Page />} />
        <Route path={Navigate.creatorsOnboardingStep3Page()} element={<CreatorOnboardinStep3Page />} />
        <Route path={Navigate.creatorsOnboardingStep4Page()} element={<CreatorOnboardinStep4Page />} />
        <Route path={Navigate.creatorsOnboardingStep5Page()} element={<CreatorOnboardinStep5Page />} />

        <Route path={Navigate.campaignsPage()} element={<CampaignsListPage />} />
        <Route path={Navigate.campaignsCreatePage()} element={<CampaignCreatePage />} />
        <Route path={Navigate.campaignsUpdatePage()} element={<CampaignUpdatePage />} />
        <Route path={Navigate.campaignsViewPage()} element={<CampaignsViewPage />} />
        <Route path={Navigate.campaignsFindInfluencers()} element={<CampaignsFindInfluencersPage />} />
        <Route path={Navigate.campaignsInviteInfluencer()} element={<CampaignsInviteInfluencerPage />} />
        <Route path={Navigate.campaignsRecommendedInfluencers()} element={<CampaignsRecommendedInfluencersPage />} />
        <Route path={Navigate.campaignsResearchInfluencer()} element={<CampaignsViewCreatorPage />} />
        <Route path={Navigate.campaignsPerformanceInfluencer()} element={<CampaignsViewCreatorPerformancePage />} />
        <Route path={Navigate.campaignsContractsInfluencer()} element={<CampaignsViewCreatorContractPage />} />
        <Route path={Navigate.campaignsPaymentInfluencer()} element={<CampaignsViewCreatorPaymentPage />} />
        <Route path={Navigate.campaignsMessageInfluencer()} element={<CampaignsMessageInfluencerPage />} />
        <Route path={Navigate.campaignsStartPage()} element={<CampaignsStartPage />} />
        <Route path={Navigate.campaignsViewInfluencer()} element={<CampaignsViewInfluencerPage />} />
        <Route path={Navigate.campaignsInvitesPage()} element={<CampaignsInvitePage />} />
        <Route path={Navigate.campaignsInfluencersPage()} element={<CampaignsInfluencersPage />} />
        <Route path={Navigate.campaignsUpdateInfluencer()} element={<CampaignUpdateInfluencerPage />} />
        <Route path={Navigate.campaignsLedgerPage()} element={<CampaignsLedgerPage />} />
        <Route path={Navigate.campaignsContentPage()} element={<CampaignsContentPage />} />
        <Route path={Navigate.campaignsStatisticsPage()} element={<CampaignsStatisticsPage />} />
        <Route path={Navigate.campaignsUpdateInfluencerInviteCompensation()} element={<CampaignsUpdateInfluencerInviteCompensationPage />} />
        <Route path={Navigate.campaignsUpdateInfluencerInviteContract()} element={<CampaignsUpdateInfluencerInviteContractPage />} />
        <Route path={Navigate.campaignsUpdateMediaPage()} element={<CampaignUpdateMediaPage />} />
        <Route path={Navigate.campaignsUpdateLinksPage()} element={<CampaignUpdateLinksPage />} />
        <Route path={Navigate.campaignsViewInfluencerInvite()} element={<CampaignsViewInfluencerInvitePage />} />


        <Route path={Navigate.communitiesPage()} element={<CommunitiesList />} />
        <Route path={Navigate.communitiesAdminListPage()} element={<CommunitiesAdminList />} />
        <Route path={Navigate.communitiesCreatePage()} element={<CommunitiesCreate />} />
        <Route path={Navigate.communitiesViewPage()} element={<CommunitiesView />} />
        <Route path={Navigate.communitiesOverviewPage()} element={<CommunitiesManage />} />
        <Route path={Navigate.communitiesManagePage()} element={<CommunitiesManage />} />
        <Route path={Navigate.communitiesUpdatePage()} element={<CommunitiesUpdate />} />
        <Route path={Navigate.communitiesCnamePage()} element={<CommunitiesCname />} />
        <Route path={Navigate.communitiesFeaturesPage()} element={<CommunitiesFeatures />} />
        <Route path={Navigate.communitiesSocialPage()} element={<CommunitiesSocial />} />
        <Route path={Navigate.communitiesMediaPage()} element={<CommunitiesMedia />} />
        <Route path={Navigate.communitiesAccessibilityPage()} element={<CommunitiesAccessibility />} />
        <Route path={Navigate.communitiesNewsletterPage()} element={<CommunitiesNewsletter />} />
        <Route path={Navigate.communitiesCssPage()} element={<CommunitiesCss />} />
        <Route path={Navigate.communitiesUsersListPage()} element={<CommunitiesUsers />} />
        <Route path={Navigate.communitiesUsersInvitePage()} element={<CommunitiesUsersInvite />} />
        <Route path={Navigate.communitiesSubscribePage()} element={<PublisherSubscribePage />} />
        <Route path={Navigate.communitiesCardsPage()} element={< CommunitiesCreditCardsPage />} />
        <Route path={Navigate.communitiesContractstPage()} element={<CommunityContractsPage />} />
        <Route path={Navigate.communitiesEmailTemplatesPage()} element={<CommunitiesEmailTemplatesPage />} />
        <Route path={Navigate.communitiesGettingStartedPage()} element={<CommunitiesGettingStarted />} />
        <Route path={Navigate.communitiesUsersManagePage()} element={<CommunitiesUsersManage />} />



        <Route path={"/streams/:id/recording/:subid"} element={<WatchRecording />} />

        <Route path={Navigate.influencersListCampaignsPage()} element={<InfluencerCampaignsPage />} />
        <Route path={Navigate.influencersFindCampaignPage()} element={<InfluencerFindCampaignsPage />} />
        <Route path={Navigate.influencersViewCampaignPage()} element={<InfluencerViewCampaignPage />} />
        <Route path={Navigate.influencersManageCampaignPage()} element={<InfluencerManageCampaignPage />} />
        <Route path={Navigate.influencersMyCampaignsPage()} element={<InfluencerMyCampaignsPage />} />
        <Route path={Navigate.influencersCampaignInvitePage()} element={<InfluencerViewCampaignInvitePage />} />
        <Route path={Navigate.influencersPayoutsCampaignPage()} element={<InfluencerPayoutsPage />} />
        <Route path={Navigate.influencersBeginCampaignPage()} element={<InfluencerBeginCampaignPage />} />
        <Route path={Navigate.influencersApplyCampaignPage()} element={<InfluencerCampaignApplyPage />} />

        <Route path={Navigate.influencersCampaignPostsCreatePage()} element={<InfluencerCreatePostPage />} />
        <Route path={Navigate.influencersCampaignPostsListPage()} element={<InfluencerListSocialMediaPostsPage />} />
        <Route path={Navigate.influencersCampaignPostsUpdatePage()} element={<InfluencerUpdatePostPage />} />

        <Route path={Navigate.influencersMessagesCreatePage()} element={<InfluencerMessagesCreatePage />} />
        <Route path={Navigate.influencersMessagesListPage()} element={<InfluencerMessagesListPage />} />
        <Route path={Navigate.influencersMessagesThreadPage()} element={<InfluencerMessagesReadPage />} />

        <Route path={Navigate.influencersDownloadAppPage()} element={<InfluencerDownloadAppPage />} />
        <Route path={Navigate.influencersGettingStartedPage()} element={<InfluencerGettingStartedPage />} />

        <Route path={Navigate.influencersTutorials()} element={<InfluencerTutorialsPage />} />
        <Route path={Navigate.influencersTutorialStarting()} element={<InfluencerTutorialsContentPage />} />
        <Route path={Navigate.influencersTutorialInvites()} element={<InfluencerTutorialsInvitesPage />} />
        <Route path={Navigate.influencersTutorialGames()} element={<InfluencerTutorialsGamesPage />} />



        <Route path={Navigate.tipsEmojiOnly()} element={<EmojisPage />} />
        <Route path={Navigate.tipsEmojiAndWatch()} element={<EmojisWatchPage />} />

        <Route path={Navigate.authLogin()} element={<LogIn />} />
        <Route path={Navigate.authRegister()} element={<SignUp />} />
        <Route path={Navigate.accountRegisterStep2()} element={<RegisterStep2 />} />
        <Route path={Navigate.accountMainPage()} element={<AccountUpdatePage />} />
        <Route path={Navigate.accountVerify()} element={<AccountVerifyPage />} />
        
        <Route path={Navigate.authForgotPassword()} element={<ForgotPassword />} />
        <Route path={Navigate.authResetPassword()} element={<ResetPassword />} />

        <Route path={Navigate.authFacebookComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authTwitchComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authGoogleComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authYoutubeComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authTikTokComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authTwitterComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authRedditComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authStripeComplete()} element={<AuthComplete />} />

        <Route path={Navigate.streamsCohostWatch()} element={<Cohostwatchstream />} />

        <Route path={Navigate.mediakitsPublishersListPage()} element={<MediakitListPage />} />
        <Route path={Navigate.mediakitsPublishersCreatePage()} element={<MediakitCreatePage />} />
        <Route path={Navigate.mediakitsPublishersUpdatePage()} element={<MediakitUpdatePage />} />
        <Route path={Navigate.mediakitsPublishersViewPage()} element={<MediakitViewPage />} />
        <Route path={Navigate.mediakitsCommunityStandalonePage()} element={<MediakitStandalonePage />} />
        <Route path={Navigate.mediakitsPublishersSharePage()} element={<MediakitSharePage />} />

        <Route path={Navigate.playtestingPublishersListPage()} element={<PlayTestGamesListPage />} />
        <Route path={Navigate.playtestingPublishersCreatePage()} element={<PlayTestGamesCreatePage />} />
        <Route path={Navigate.playtestingPublishersViewPage()} element={<PlayTestsListPage />} />
        <Route path={Navigate.playtestingPublishersViewTestPage()} element={<PlayTestDetailsPage />} />
        <Route path={Navigate.playtestingPublishersViewQuestionsPage()} element={<PlayTestManageQuestionsPage />} />
        <Route path={Navigate.playtestingPublishersInvitePage()} element={<PlayTestInviteTestersPage />} />
        <Route path={Navigate.playtestingPublishersUpdatePage()} element={<PlayTestGamesUpdatePage />} />
        <Route path={Navigate.playtestingPublishersViewReviewPage()} element={<PlayTestReviewTestPage />} />
        <Route path={Navigate.playtestingPublishersPreviewPage()} element={<PlayTestGamesPreviewPage />} />
        <Route path={Navigate.playtestingPublishersMessagesPage()} element={<PlayTestMessagePage />} />

        <Route path={Navigate.playtestingUserGames()} element={<PlayTestFindGamesToTestPage />} />
        <Route path={Navigate.playtestingUserTests()} element={<PlayTestMyPlayTestsPage />} />
        <Route path={Navigate.playtestingUserTest()} element={<PlayTestDetailsForTesterPage />} />
        <Route path={Navigate.playtestingUserAnswers()} element={<PlayTestSubmitPlayTestAnswersPage />} />
        <Route path={Navigate.playtestingUserGame()} element={<PlayTestGameDetailsPage />} />



        <Route path={Navigate.messagesListPage()} element={<MessagesListPage />} />
        <Route path={Navigate.messagesCreatePage()} element={<MessagesCreatePage />} />
        <Route path={Navigate.messagesReadPage()} element={<MessagesReadPage />} />
        <Route path={Navigate.messagesThreadPage()} element={<MessagesReadPage />} />

        <Route path={Navigate.gameshowPublisherListPage()} element={<GameShowsListPage />} />
        <Route path={Navigate.gameshowPublisherCreatePage()} element={<GameShowsCreatePage />} />
        <Route path={Navigate.gameshowPublisherViewPage()} element={<GameShowsViewPage />} />
        <Route path={Navigate.gameshowPublisherUpdatePage()} element={<GameShowsUpdatePage />} />
        <Route path={Navigate.gameshowPublisherPromotePage()} element={<GameShowsPromotePage />} />

        <Route path={Navigate.publishersDashboardPage()} element={<PublishersDashboardPage />} />
        <Route path={Navigate.publishersGamificationPage()} element={<PublishersGamificationPage />} />
        <Route path={Navigate.publishersOptimizationPage()} element={<PublishersOptimizationPage />} />
        <Route path={Navigate.publishersBenefitsPage()} element={<PublisherBenefitPage />} />
        <Route path={Navigate.publishersAcquisitionPage()} element={<PublisherUserAcquistion />} />
        <Route path={Navigate.publishersRegisterPage()} element={<PublisherRegisterPage />} />
        <Route path={Navigate.publishersPilotPage()} element={<PublisherPilotsPage />} />
        <Route path={Navigate.publishersPricingPage()} element={<PublisherPricingPage />} />
        <Route path={Navigate.publishersDatabasePage()} element={<PublishersDatabasePage />} />
        <Route path={Navigate.publishersAIPage()} element={<PublishersAIPage />} />
        <Route path={Navigate.publishersCRMPage()} element={<PublishersCRMPage />} />
        <Route path={Navigate.publishersContentPage()} element={<PublishersContentPage />} />
        <Route path={Navigate.publishersHoldaysPage()} element={<PublishersHolidayPage />} />
        <Route path={Navigate.publishersPlayTesterPage()} element={<PublishersPlayersPage />} />
        <Route path={Navigate.publishersNewsletterPage()} element={<PublishersNewsletterPage />} />
        <Route path={Navigate.publishersShowcasesPage()} element={<PublishersShowcasesPage />} />
        <Route path={Navigate.publishersMediakitPage()} element={<PublishersMediaKitPage />} />
        <Route path={Navigate.publishersDevlogsPage()} element={<PublisherDevlogPage />} />
        <Route path={Navigate.publishersSocialPage()} element={<PublisherSocialSchedulerPage />} />
        <Route path={Navigate.publishersCommunityPage()} element={<PublishersCommunityPage />} />



        <Route path={Navigate.publishersGamificationPage2()} element={<PublishersGamificationPage />} />
        <Route path={Navigate.publishersOptimizationPage2()} element={<PublishersOptimizationPage />} />
        <Route path={Navigate.publishersBenefitsPage2()} element={<PublisherBenefitPage />} />
        <Route path={Navigate.publishersAcquisitionPage2()} element={<PublisherUserAcquistion />} />
        <Route path={Navigate.publishersDatabasePage2()} element={<PublishersDatabasePage />} />
        <Route path={Navigate.publishersAIPage2()} element={<PublishersAIPage />} />
        <Route path={Navigate.publishersCRMPage2()} element={<PublishersCRMPage />} />
    

        <Route path={Navigate.publishersToolsPage()} element={<PublisherGameFreeToolsPage />} />
        <Route path={Navigate.publishersToolsForumPage()} element={<PublisherGameForumsPage />} />
        <Route path={Navigate.publishersToolsWikiPage()} element={<PublisherGameWikiPage />} />
        <Route path={Navigate.publishersToolsDatabasePage()} element={<PublisherGamDatabasePage />} />
        <Route path={Navigate.publishersToolsPricingPage()} element={<PublisherGamePricingPage />} />
        <Route path={Navigate.publishersToolsPRPage()} element={<PublisherGamePRPage />} />
        <Route path={Navigate.publishersToolsInfluencersPage()} element={<PublisherGameInfluencersPage />} />
        <Route path={Navigate.publishersToolsShowcasesPage()} element={<PublisherGameShowsPage />} />
        <Route path={Navigate.publishersToolsGuidePage()} element={<PublisherGameGuidePage />} />
        <Route path={Navigate.publishersToolsEmailPage()} element={<PublisherGameNewsletterPage />} />

        <Route path={Navigate.publishersOnboardingStep1Page()} element={<PublisherOnboardinStep1Page />} />
        <Route path={Navigate.publishersOnboardingStep2Page()} element={<PublisherOnboardinStep2Page />} />
        <Route path={Navigate.publishersOnboardingStep3Page()} element={<PublisherOnboardingStep3Page />} />
        <Route path={Navigate.publishersOnboardingStep4Page()} element={<PublisherOnboardingStep4Page />} />

        <Route path={Navigate.publishersMessagesCreatePage()} element={<PublisherMessagesCreatePage />} />
        <Route path={Navigate.publishersMessagesListPage()} element={<PublisherMessagesListPage />} />
        <Route path={Navigate.publishersMessagesThreadPage()} element={<PublisherMessagesReadPage />} />

        <Route path={Navigate.publishersTutorialsPage()} element={<PublisherTutorialsPage/>} />

        <Route path={Navigate.publishersTutorialsInvitesPipelinesPage()} element={<PublisherTutorialInvitePipelinesPage />} />
        <Route path={Navigate.publishersTutorialsInvitesDatabasePage()} element={<PublisherTutorialInviteDatabasePage />} />
        <Route path={Navigate.publishersTutorialsInvitesRecommendedPage()} element={<PublisherTutorialInviteRecommendedPage />} />
        <Route path={Navigate.publishersTutorialsInvitesInvitePage()} element={<PublisherTutorialInvitesPage />} />
        <Route path={Navigate.publishersTutorialsInvitesCustomPage()} element={<PublisherTutorialInviteCustomEmailsPage />} />
        <Route path={Navigate.publishersTutorialsInvitesNotesPage()} element={<PublisherTutorialInviteNotesPage />} />
        <Route path={Navigate.publishersTutorialsInvitesNegotiationsPage()} element={<PublisherTutorialInviteNegotiationsPage />} />

        <Route path={Navigate.publishersTutorialsCampaignsOverviewPage()} element={<PublisherTutorialCampaignsOverviewPage />} />
        <Route path={Navigate.publishersTutorialsCampaignsCreatePage()} element={<PublisherTutorialCampaignsCreatePage />} />
        <Route path={Navigate.publishersTutorialsCampaignsChecklistPage()} element={<PublisherTutorialCampaignsChecklistPage />} />
        <Route path={Navigate.publishersTutorialsCampaignsRatecardPage()} element={<PublisherTutorialCampaignsRateCardPage />} />
        <Route path={Navigate.publishersTutorialsSchedulersCreatePage()} element={<PublisherTutorialSchedulerCreatePage />} />


        <Route path={Navigate.reportsMainPage()} element={<ReportsMainPage />} />
        <Route path={Navigate.reportsViewTitlePage()} element={<ReportsViewTitlePage />} />


        <Route path={Navigate.schedulerListPage()} element={<SchedulerListPage />} />
        <Route path={Navigate.schedulerCreatePage()} element={<SchedulerCreatePage />} />
        <Route path={Navigate.schedulerSocialsPage()} element={<SchedulerSocialPage />} />
        <Route path={Navigate.schedulerUpdatePage()} element={<SchedulerUpdatePage />} />
        <Route path={Navigate.schedulerViewPage()} element={<SchedulerViewPage />} />
        <Route path={Navigate.schedulerUpdatesListPage()} element={<SchedulerListUpdatesPage />} />
        <Route path={Navigate.schedulerPostsPage()} element={<SchedulerListSocialMediaPostsPage />} />
        <Route path={Navigate.schedulerUsersPage()} element={<SchedulerUsersPage />} />
        <Route path={Navigate.schedulerInvitePage()} element={<SchedulerInviteUserPage/>} />
        <Route path={Navigate.schedulerTonePage()} element={<SchedulerTonePage/>} />
        <Route path={Navigate.schedulerReportsPage()} element={<SchedulerReportsPage/>} />

         
        <Route path={Navigate.schedulerUpdatesCreatePage()} element={<SchedulerCreateUpdatePage />} />
        <Route path={Navigate.schedulerUpdatesUpdatePage()} element={<SchedulerUpdateUpdatePage />} />

        <Route path={Navigate.schedulerPostsUpdatePage()} element={<SchedulerUpdateSocialMediaPost/>} />
        <Route path={Navigate.schedulerPostsViewPage()} element={<SchedulerViewSocialMediaPost/>} />

        <Route path={Navigate.newsletterListPage()} element={<NewslettersListPage />} />
        <Route path={Navigate.newsletterCreatePage()} element={<NewslettersCreatePage />} />
        <Route path={Navigate.newsletterViewPage()} element={<NewsletterViewPage />} />
        <Route path={Navigate.newsletterUpdatePage()} element={<NewsletterUpdatePage />} />
        <Route path={Navigate.newsletterSubscribersPage()} element={<NewsletterSubscribersPage />} />
        <Route path={Navigate.newsletterSubscribePage()} element={<NewsletterSubscribePage/>} />
        <Route path={Navigate.newsletterImportPage()} element={<NewsletterImportPage/>} />

        <Route path={Navigate.newsletterCampaignCreatePage()} element={<NewsletterCampaignCreatePage />} />
        <Route path={Navigate.newsletterCampaignSendPage()} element={<NewsletterCampaignSendPage />} />
        <Route path={Navigate.newsletterCampaignUpdatePage()} element={<NewsletterCampaignUpdatePage />} />
        <Route path={Navigate.newsletterCampaignViewPage()} element={<NewsletterCampaignViewPage />} />


        <Route path={Navigate.usersInvitesPage()} element={<InfluencerInvitesPage />} />
        <Route path={Navigate.usersPayoutsPage()} element={<InfluencerPayoutsPage />} />

        <Route path={Navigate.authTwitch()} element={<AuthTwitch />} />
        <Route path={Navigate.authTikTok()} element={<AuthTikTok />} />
        <Route path={Navigate.authFacebook()} element={<AuthFacebook />} />
        <Route path={Navigate.authGoogle()} element={<AuthGoogle />} />
        <Route path={Navigate.authTwitter()} element={<AuthTwitter />} />
        <Route path={Navigate.authReddit()} element={<AuthReddit />} />
        <Route path={Navigate.authStripe()} element={<AuthStripe />} />
        <Route path={Navigate.authDocusign()} element={<AuthDocusign />} />
        <Route path={Navigate.authSimpleSign()} element={<AuthSimplesign />} />

        <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
      {loadLiveChat && (
        <Suspense fallback={<div>Loading chat...</div>}>
          <LiveChatWidget
            license="18255615"
            visibility="minimized"
            onNewEvent={handleNewEvent}
          />
        </Suspense>
      )}
    </>
  );
}

export default Template;
