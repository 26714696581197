import React, { useState, useEffect, lazy, Suspense } from 'react';
import ReactGA from 'react-ga';
import Glitch from 'glitch-javascript-sdk';
import ReactDOM from 'react-dom/client';
import RingLoader from 'react-spinners/RingLoader';
import Template from './templates/marketing';

import mixpanel from 'mixpanel-browser';

// Initialize Mixpanel with your token
mixpanel.init('e4566227541691bc68bebf9b291854ca', {
  debug: true, // Enable debug mode if you want verbose output
});


const App = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState(null);

  

  const override = {
    display: "block",
    margin: "auto",
    marginTop: "45%",
  };

  useEffect(() => {

     // Handler for click events
     const handleClick = (event) => {
      const target = event.target;

      // Check if the clicked element is a button or link
      if (target.matches('button, a')) {
        const eventData = {
          elementType: target.tagName,
          elementText: target.innerText || target.textContent,
          elementName: target.name || target.getAttribute('name'),
          elementId: target.id || target.getAttribute('id'),
          elementClass: target.className,
          href: target.href || target.getAttribute('href'),
        };

        mixpanel.track('Element Clicked', eventData);
      }
    };

    // Handler for input changes
    const handleInputChange = (event) => {
      const target = event.target;

      // Check if the input element is of type input, select, or textarea
      if (target.matches('input, select, textarea')) {
        const eventData = {
          elementType: target.tagName,
          elementName: target.name || target.getAttribute('name'),
          elementId: target.id || target.getAttribute('id'),
          elementClass: target.className,
          value: target.value,
        };

        mixpanel.track('Input Changed', eventData);
      }
    };

    // Attach event listeners
    document.addEventListener('click', handleClick);
    document.addEventListener('change', handleInputChange);
    document.addEventListener('input', handleInputChange);
    
    const fetchData = async () => {
      try {
        const domain = getDomain();
        if (domain === 'www' || domain === process.env.REACT_APP_SITE_DOMAIN) {
          setLoading(false);
        } else {
          const response = await Glitch.api.Communities.findByDomain(domain);
          setData(response.data.data);

          Glitch.config.Config.setCommunity(response.data.data);
          Glitch.util.Storage.set('community_id', response.data.data.id);
          Glitch.util.Storage.set('community', response.data.data);

          let community = response.data.data;
          let templateDir = community?.template?.directory + community?.template?.entry_point_file;

          if (community.custom_css) {
            const styleElement = document.createElement('style');
            styleElement.innerHTML = community.custom_css;
            document.head.appendChild(styleElement);
          }

          loadSite(templateDir);
        }
      } catch (error) {
        let templateDir = process.env.REACT_APP_TEMPLATE_DIRECTORY;
        loadSite(templateDir);
        setData({});
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  async function loadSite(templateDir) {
    if (templateDir) {
      try {
        const loaded = await import(`${templateDir}`);
        setTemplate(loaded);
      } catch (error) {
        const loaded = await import(`./templates/error/index.js`);
        setTemplate(loaded); // Use a default error template if loading fails
      }
    } else {
      const loaded = await import(`./templates/error/index.js`);
      setTemplate(loaded); // Use a default error template if template directory is not defined
    }

    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  function getDomain() {
    const currentDomain = window.location.hostname;

    if (currentDomain === process.env.REACT_APP_SITE_DOMAIN) {
      const wwwDomain = `www.${process.env.REACT_APP_SITE_DOMAIN}`;
      window.location.replace(`${window.location.protocol}//${wwwDomain}${window.location.pathname}${window.location.search}`);
      return 'www'; // Return 'www' to handle it correctly
    } else if (currentDomain.endsWith(process.env.REACT_APP_SITE_DOMAIN)) {
      const subdomain = currentDomain.split('.')[0];
      return subdomain;
    } else {
      return currentDomain;
    }
  }

  if (loading) {
    return <RingLoader loading={loading} cssOverride={override} size={60} color="#0dcaf0" />;
  }

  if (template) {
    return (
      <Suspense fallback={<div>Loading template...</div>}>
        <template.default />
      </Suspense>
    );
  }

  return <Template />;
};

export default App;
