import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Breadcrumbs from '../../component/layout/breadcrumb';
import PublisherHeader from '../../component/layout/publisherheader';
import CampaignLedger from '../../component/section/campaigns/campaign_ledger';
import Navigate from '../../../../util/Navigate';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';

const CampaignsStatisticsPage = () => {
    const [campaign, setCampaign] = useState({});
    const [statistics, setStatistics] = useState({});
    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Campaigns.view(id).then(response => {
            setCampaign(response.data.data);
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Campaigns.statistics(id).then(response => {
            setStatistics(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    }, [id]);

    const formatPercentage = (value) => {
        return isNaN(value) || value === null ? "0.00" : Number(value).toFixed(2);
    };

    const formatNumber = (value) => {
        return isNaN(value) || value === null ? "0.00" : Number(value).toFixed(2);
    };

    const renderEmailStatistics = () => {
        const emails = ["email_1", "email_2", "email_3", "email_4", "email_5"];
        return emails.map((email, index) => (
            <tr key={index}>
                <td>Email {index + 1}</td>
                <td>{statistics[email]?.total}</td>
                <td>{statistics[email]?.responded}</td>
                <td>{formatPercentage(statistics[email]?.percentage)}%</td>
            </tr>
        ));
    };

    const renderSocialStatistics = (type) => {
        const platforms = ["all", "reddit", "twitter", "tiktok", "instagram", "youtube", "facebook"];
        if (!statistics.social || !statistics.social[type]) {
            return null;
        }
        return platforms.map((platform, index) => (
            <tr key={index}>
                <td>{platform.charAt(0).toUpperCase() + platform.slice(1)}</td>
                <td>{statistics.social[type]?.totals[platform]}</td>
                <td>{formatNumber(statistics.social[type]?.average[platform])}</td>
                <td>{statistics.social[type]?.max[platform]}</td>
                <td>{statistics.social[type]?.min[platform]}</td>
            </tr>
        ));
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />
                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs items={[
                            { name: 'Campaigns', link: Navigate.campaignsPage() },
                            { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                            { name: 'Ledger', link: Navigate.campaignsLedgerPage(campaign.id) },
                        ]} />
                        <div className="section-wrapper">
                            <div className="pageheader-thumb mb-4"></div>
                            <h2 className="pageheader-title"><i className="fas fa-chart-bar"></i> View Campaign Statistics</h2>
                            <p className="lead">See all the statistics for the campaign {campaign?.name}.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="card mb-4">
                                <div className="card-header">Email Open Statistics</div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                                <th>Total Sent</th>
                                                <th>Responded</th>
                                                <th>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderEmailStatistics()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card mb-4">
                                <div className="card-header">Email General Statistics</div>
                                <div className="card-body">
                                    <table className="table">
                                    <thead>
                                            <tr>
                                                <th>Action</th>
                                                <th>Total Sent</th>
                                                <th>Result</th>
                                                <th>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Total Bounced</td>
                                                <td>{statistics.bounced?.total}</td>
                                                <td>{statistics.bounced?.bounced}</td>
                                                <td>{formatPercentage(statistics.bounced?.percentage)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Total Spam</td>
                                                <td>{statistics.spam?.total}</td>
                                                <td>{statistics.spam?.spam}</td>
                                                <td>{formatPercentage(statistics.spam?.percentage)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Total Not Sent</td>
                                                <td>{statistics.not_sent?.total}</td>
                                                <td>{statistics.not_sent?.not_sent}</td>
                                                <td>{formatPercentage(statistics.not_sent?.percentage)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Total Opened</td>
                                                <td>{statistics.opened?.total}</td>
                                                <td>{statistics.opened?.opened}</td>
                                                <td>{formatPercentage(statistics.opened?.percentage)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Total Responded</td>
                                                <td>{statistics.responded?.total}</td>
                                                <td>{statistics.responded?.responded}</td>
                                                <td>{formatPercentage(statistics.responded?.percentage)}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="card mb-4">
                                <div className="card-header">Stream Statistics</div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Total Views</td>
                                                <td>{statistics.stream_total_views}</td>
                                            </tr>
                                            <tr>
                                                <td>Average Views</td>
                                                <td>{formatNumber(statistics.stream_average_views)}</td>
                                            </tr>
                                            <tr>
                                                <td>Max Views</td>
                                                <td>{statistics.stream_max_views}</td>
                                            </tr>
                                            <tr>
                                                <td>Min Views</td>
                                                <td>{statistics.stream_min_views}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Chats</td>
                                                <td>{statistics.stream_total_chats}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card mb-4">
                                <div className="card-header">Opened Email Breakdown</div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Opened First Email</td>
                                                <td>{statistics.opened?.opened_first_email}</td>
                                            </tr>
                                            <tr>
                                                <td>Opened Second Email</td>
                                                <td>{statistics.opened?.opened_second_email}</td>
                                            </tr>
                                            <tr>
                                                <td>Opened Third Email</td>
                                                <td>{statistics.opened?.opened_third_email}</td>
                                            </tr>
                                            <tr>
                                                <td>Opened Fourth Email</td>
                                                <td>{statistics.opened?.opened_fourth_email}</td>
                                            </tr>
                                            <tr>
                                                <td>Opened Fifth Email</td>
                                                <td>{statistics.opened?.opened_fifth_email}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header">Social Media Statistics</div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Platform</th>
                                                <th>Total</th>
                                                <th>Average</th>
                                                <th>Max</th>
                                                <th>Min</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr><th colSpan="5">Views</th></tr>
                                            {renderSocialStatistics('views')}
                                            <tr><th colSpan="5">Comments</th></tr>
                                            {renderSocialStatistics('comments')}
                                            <tr><th colSpan="5">Shares</th></tr>
                                            {renderSocialStatistics('shares')}
                                            <tr><th colSpan="5">Engagements</th></tr>
                                            {renderSocialStatistics('engagements')}
                                            <tr><th colSpan="5">Clicks</th></tr>
                                            {renderSocialStatistics('clicks')}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            <Footer />
        </>
    );
};

export default CampaignsStatisticsPage;
